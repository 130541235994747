* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  height: 100%;
}

body {
  max-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

}

h3 {
  font-size: 1.5rem;
  color: rgb(56, 56, 56);
  margin: 0.5rem 0rem;
}

h4 {
  font-size: 1rem;
  color: rgb(56, 56, 56);
}

a {
  color: rgb(56, 56, 56);
}
